import React, { useEffect, useState, useRef } from "react";
import { Text, TextField, Icon, Select, Button, Table, TableCell, TableHeading, ButtonGroup, Modal, Snack, Snackbar, Popover, Spinner } from "@nike/eds";
import axios from 'axios';
import apiService from '../../services/service';
import API_INTERFACE_URI from "../../constants/uri.constants";
import './Fills.css';
import InfoModal from "./InfoModal.js";
import * as XLSX from 'xlsx';
import SnackBar from "../SnackBar/SnackBar.js";

function Fills({ groups }) {
    let height = window.innerHeight;
    const [snackMessage, setShowSnack] = useState({ message: "", status: "" });
    const [snackModalMessage, setShowModalSnack] = useState({message: "", status:""});
    const [snackUpdtModalMessage, setShowUpdtModalSnack] = useState({message: "", status:""});
    const [visible, setVisible] = useState(false);
    const [modalSpinner, setModalSpinner] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [selectedCarrier, setSelectedCarrier] = useState([]);
    const [selectedDC, setSelectedDC] = useState([]);
    const [selectedCapacityBucket, setSelectedCapacityBucket] = useState([]);
    const [dcArray, setDCarray] = useState([]);
    const [carrierArray, setCarrierArray] = useState([]);
    const [capacityBucketArray, setCapacityBucketArray] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [dateRange, setDateRange] = useState();
    const [searchText, setSearchText] = useState('');
    const [displayMessage, setDisplayMessage] = useState('Select search criteria and submit to display results.')
    const [nextDate, setNextDate] = useState(startDate);
    const [previousDate, setPreviousDate] = useState(startDate);
    const [showResults, setShowResults] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [previousButton, setPreviousButton] = useState(true);
    const [endOfRecords, setEndOfRecords] = useState();
    const [fillsData, setFillsData] = useState([]);
    const [currentStartDate, setCurrentStartDate] = useState();
    const [header, setHeader] = useState([]);
    const [consolidatedResult, setConsolidatedResult] = useState([]);
    const [showEditModal, setEditModal] = useState(false);
    const [infoModal, setInfoModal] = useState({});
    const [editDetails, setEditDetails] = useState();
    const [previousDetails, setPreviousDetails] = useState();
    const [outboxFunction, setOutboxFunction] = useState(true);
    const [sortOrderDate , setSortOrderDate]= useState('des');
    const [sortOrderPR, setSortOrderPR] = useState('asc');
    const [sortOrderSR, setSortOrderSR] = useState('asc');
    const [priorityRank, setPriorityRank] = useState();
    const [priorityFillPercentage, setPriorityFillPercentage] = useState();
    const [secondaryRank, setSecondaryRank] = useState();
    const [secondaryFillPercentage, setSecondaryFillPercentage] = useState();
    const [sortOrderPP, setSortOrderPP] = useState('asc');
    const [sortOrderSP, setSortOrderSP] = useState('asc');
    function onNext() {
        getFillsData(nextDate)
    }

    function onPrevious() {
        getFillsData(previousDate)
    }
    const inputRef = useRef();

    const onChangeSearch = e => setSearchText(e.target.value);

    async function getMasterData() {
        try {
            const res = await apiService.get(API_INTERFACE_URI.GET_MASTER_DATA);
            if (res && res.data && res.data.objects) {
                let dcArray = [
                    ...new Map(res.data.objects.map((item) => [item["nodeName"], item])).values()
                ];
                setDCarray([...dcArray.map((data) => ({ "value": data.PlntCd, "label": data.nodeName })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
                let carrierArray = [
                    ...new Map(res.data.objects.map((item) => [item["CarrierGroupName"], item])).values()
                ];
                setCarrierArray([...carrierArray.map((data) => ({ "value": data.CarrierGroupName, "label": data.CarrierGroupName })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
            }
        } catch (e) {
            setShowResults(false);
            setDisplayMessage("Priority & Secondary Fills page can not be displayed at the moment, Please try again later.");
        }
    }
    async function getCapacityBucket() {
        try {
            const res = await apiService.get(API_INTERFACE_URI.CAPACITY_BUCKET);
            if (res && res.data) {
                setCapacityBucketArray([...res.data.map((data) => ({ "value": data, "label": data })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))])
            }
        }
        catch (e) {
            setShowResults(false);
            setDisplayMessage("Priority & Secondary Fills page can not be displayed at the moment, Please try again later.");
        }
    }
    useEffect(() => {
        setPreviousDate(startDate);
        getMasterData();
        getCapacityBucket();
    }, [])

    function selectCarrierValues(data, item) {
        setSelectedCarrier([...data])
    }

    function selectDCValues(data, item) {
        setSelectedDC([...data])
    }

    function selectCapacityBucketValues(data, item) {
        setSelectedCapacityBucket([...data])
    }

    function onStartDate(e) {
        setStartDate(e.target.value)
        var d = new Date(e.target.value);
        d.setDate(d.getDate() + 89);
        var dateRange = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
        setDateRange(dateRange);
        setEndDate('');
    }

    function cancelUpload() {
        inputRef.current.value = "";
        setSelectedFile('');
        setVisible(!visible);
    }

    const handleFileInput = (file) => {
        setSelectedFile(file);
    }

    function validateFile(file) {
        const validDate = new RegExp('^(1[0-2]|0?[1-9])/(3[01]|[12][0-9]|0?[1-9])/(?:[0-9]{2})?[0-9]{2}$');
        const validNumber = new RegExp('^[0-9]*$');
        const validAlphaNumber = new RegExp('^[0-9a-zA-Z]*$');
        const reader = new FileReader();
        reader.onload = function (evt) {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[1];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { raw: false });
            let alphanumericColumns = ['shipvia', 'capacitybucket'];
            let numericColumns = ['priority ranking', 'secondary ranking', 'priority percentage', 'secondary percentage'];
            let invalidRecords = [];
            if(data && data.length > 0){
                data.forEach((r, i) => {
                    Object.entries(r).forEach(([key, value], index) => {     
                        if (key.toLowerCase() === 'from' || key.toLowerCase() === 'to') {
                            if (!validDate.test(value)) {                                    
                                invalidRecords.push("Invalid Date format at row " + (i + 2));                                    
                            }
                        }
                        if (numericColumns.includes(key.toLowerCase())) {
                            if (!validNumber.test(value) || value < 0 || value > 100) {                                    
                                invalidRecords.push("Invalid value for " + key+ " at row "+ (i+2));                                
                            }
                        }    
                        if (key.toLowerCase() === 'plntcd') {
                            if (!validNumber.test(value)) {                                
                                invalidRecords.push( "Invalid value for " + key+ " at row "+ (i+2));                                
                            }
                        }   
                        if (alphanumericColumns.includes(key.toLowerCase())) {
                            if (!validAlphaNumber.test(value)) {                                
                                invalidRecords.push( "Invalid value for " + key+ " at row "+ (i+2));                                
                            }
                        }                 
                    })
                    if(!(parseInt(r['Priority Percentage']) <= parseInt(r['Secondary Percentage']))){
                        invalidRecords.push( "Secondary Percentage should be greater than or equal to  Priority Percentage at row "+ (i+2));
                    }
                })
            } else{                
                invalidRecords.push("Empty Sheet!");                
            }
            if(invalidRecords && invalidRecords.length > 0){
                setShowModalSnack({message :invalidRecords, status: "error"});
                setModalSpinner(false);
                inputRef.current.value = "";
                setSelectedFile(''); 
            }else{
                uploadFiletoS3(file);
            }
        };
        reader.readAsBinaryString(file);
    }
    
    async function getFillsData(startDates) {
        let size = 30;
        setCurrentStartDate(startDates);
        setPreviousButton(startDates == startDate ? false : true)
        if (startDates && endDate) {
            var d = new Date(startDates);
            d.setDate(d.getDate() - 30);
            let tempDate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
            let preDate = new Date(tempDate).getTime() < new Date(startDate).getTime() ? startDate : tempDate;
            setPreviousDate(preDate);
            var p = new Date(preDate);
            p.setDate(p.getDate() - 30);
            let selectedDcValues = selectedDC.map((e) => e.value).toString();
            let selectedCarrierValues = selectedCarrier.map((e) => e.value).toString();
            let selectedCapacityBucketValues = selectedCapacityBucket.map((e) => e.value).toString();
            let shipViaSearch = searchText;

            try {
                const res = await apiService.get(
                    API_INTERFACE_URI.GET_FILLS_DATA + "size=" + size + "&fromDate=" + startDates + "&toDate=" + endDate + "&dc=" + selectedDcValues + "&carrier=" + selectedCarrierValues + "&capacityBucket=" + selectedCapacityBucketValues + "&shipVia=" + shipViaSearch
                );
                if (res && res.data && res.data.records) {
                    setSortOrderDate("des")
                    setShowResults(true);
                    res.data.records.forEach((e) => {
                        let nodeName = dcArray.filter((a) => a.value == e.nodeCode)[0].label;
                        e.nodeName = nodeName;
                    })
                    setFillsData(res.data.records)
                    setEndOfRecords(res.data.endOfRecords)
                    if (!res.data.endOfRecords) {
                        setNextDate(res.data.nextStartDate)
                    }
                }
                if (res && res.data && res.data.records.length == 0) {

                    setShowResults(false);
                    setFillsData([])
                    setDisplayMessage("No Records found for above search criteria.")
                }
            } catch (e) {
                setShowResults(false);
                setFillsData([])
                setDisplayMessage("Priority & Secondary Fills data could not be retrieved, Please try again later.")
            }
        } else {
            setShowSnack({ message: "Please select start date and end date!", status: "error" })
        }
    }
    
    useEffect(() => {
        displayFillsData();
    }, [fillsData])

    async function displayFillsData() {
        var consolidateResultList = [];
        if (fillsData && fillsData.length > 0) {
            fillsData.map((a) => {
                a.fills.map((b) => {
                    var dte = b.date.split('-');
                    let tempDte = dte[1] + '/' + dte[2] + '/' + dte[0];
                    consolidateResultList.push({
                        "nodeCode": a.nodeCode,
                        "nodeName": a.nodeName,
                        "capacityBucket": a.capacityBucket,
                        "shipVia": a.shipVia,
                        "providerName": a.providerName,
                        "fillsId": b.id,
                        "date": tempDte,
                        "priorityFillPercentage": b.priorityFillPercentage,
                        "secondaryFillPercentage": b.secondaryFillPercentage,
                        "priorityRank": b.priorityRank,
                        "secondaryRank": b.secondaryRank,
                        "history": b.history,
                        "statuses": b.statuses
                    });
                });
            });
            consolidateResultList.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.date) - new Date(b.date);
            });
            setConsolidatedResult(consolidateResultList);
            setShowSpinner(false);
        } else {
            setConsolidatedResult([])
        }

    }

    async function formatData(fillsResponse) {
        var formatedData = [];
        if (fillsResponse && fillsResponse.length > 0) {
            fillsResponse.map((a) => {
                a.fills.map((b) => {
                    var d = b.date.split('-');
                    let tempDateB = d[1] + '/' + d[2] + '/' + d[0];
                    formatedData.push({
                        "From": tempDateB,
                        "To": tempDateB,
                        "PlntCd": a.nodeCode,
                        "Shipvia": a.shipVia,
                        "Capacitybucket": a.capacityBucket,
                        "Priority Ranking": b.priorityRank,
                        "Priority Percentage": b.priorityFillPercentage,
                        "Secondary Ranking": b.secondaryRank,
                        "Secondary Percentage": b.secondaryFillPercentage
                    });
                });
            });
            formatedData.sort(function (a, b) {
                return new Date(a["From"]) - new Date(b["From"]);
            });
            return formatedData;
        } else {
            return []
        }
    }

    async function downloadExcel() {
        let selectedDcValues = selectedDC.map((e) => e.value).toString();
        let selectedCarrierValues = selectedCarrier.map((e) => e.value).toString();
        let selectedCapacityBucketValues = selectedCapacityBucket.map((e) => e.value).toString();
        let shipViaSearch = searchText;
        let maxSize = 90;
        try {
            const res = await apiService.get(
                API_INTERFACE_URI.GET_FILLS_DATA + "size=" + maxSize + "&fromDate=" + startDate + "&toDate=" + endDate + "&dc=" + selectedDcValues + "&carrier=" + selectedCarrierValues + "&capacityBucket=" + selectedCapacityBucketValues + "&shipVia=" + shipViaSearch
            );
            const sheet1 = [
                {'Column Format & Index' :'From', '':'To', ' ':'PlntCd', '  ':'Shipvia', '   ':'Capacitybucket', '    ':'Priority Ranking', '     ':'Priority percentage', '      ':'Secondary Ranking', '       ':'Secondary percentage'}, 
                {'Column Format & Index' :''}, 
                {'Column Format & Index' :'TOP processes the data by the column Index and file with the exact column names is highly recommended'},
                {'Column Format & Index' :''}, 
                {'Column Format & Index' :'Row', '':'Details', ' ':'Sample', '  ':'Comment'},
                {'Column Format & Index' :''}, 
                {'Column Format & Index' :'From', '':'Start Date', ' ':'7/13/2023'}, 
                {'Column Format & Index' :'To', '':'End Date', ' ':'7/20/2023'}, 
                {'Column Format & Index' :'PlntCd', '':'Node Code', ' ':1014}, 
                {'Column Format & Index' :'Shipvia', '':'Ship Via Code', ' ':'5F2D'}, 
                {'Column Format & Index' :'Capacitybucket', '':'Capacity Bucket', ' ':'F2DA'}, 
                {'Column Format & Index' :'Priority Ranking', '':'Priority Ranking', ' ':1, '  ':'Value not used by Uber Freight'}, 
                {'Column Format & Index' :'Priority Percentage', '':'Priority Percentage', ' ':79}, 
                {'Column Format & Index' :'Secondary Ranking', '':'Secondary Ranking', ' ':5, '  ':'Value not used by Uber Freight'}, 
                {'Column Format & Index' :'Secondary Percentage', '':'Secondary Percentage', ' ':95}, 
            ]
            const sheet2 = await formatData(res.data.records);
            const ws1 = XLSX.utils.json_to_sheet(sheet1);
            const ws2 = XLSX.utils.json_to_sheet(sheet2);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws1, 'Definition');
            XLSX.utils.book_append_sheet(wb, ws2, 'Fills_Data');
            XLSX.writeFile(wb, 'fills_data.xlsx');
        } catch (e) {
            console.log('error', e)
        }
    }

    async function uploadFiletoS3(file) {       
        try {
            const res = await apiService.get(API_INTERFACE_URI.GET_PRESIGNED_URL + "fileName=capacity-management-fills/" + file.name);
            if (res.data.url) {
                const presignedS3Url = res.data.url;
                const axiosResponse = await axios.put(presignedS3Url, file);
                if (axiosResponse.status == 200) {
                    let fileDetails = { "fileName": file.name }
                    try {
                        const saveDataResponse = await apiService.post(API_INTERFACE_URI.SAVE_FILLS_DATA, fileDetails);
                        if (saveDataResponse.data.failedRecords.length == 0) {
                            inputRef.current.value = "";
                            setSelectedFile('');
                            setVisible(!visible);
                            setModalSpinner(false);
                            setShowSnack({ message: "File Uploaded Successfully !", status: "success" });
                            (startDate && endDate) && getFillsData(startDate);
                        } else if (saveDataResponse.data.failedRecords.length > 0) {
                        
                            inputRef.current.value = "";
                            setSelectedFile('');
                            setModalSpinner(false);
                            setShowModalSnack({ message:  saveDataResponse.data.failedRecords, status: "error" });
                        }
                    } catch (e) {
                        console.log('error', e);
                        inputRef.current.value = "";
                        setSelectedFile('');
                        setModalSpinner(false);
                        setShowModalSnack({ message: "Something went wrong. Please contact your administrator.", status: "error" });
                    }
                }
            }
        } catch (e) {
            console.log('error', e);
            setModalSpinner(false);
            setShowModalSnack({ message: "Something went wrong. Please clear cache or contact your administrator.", status: "error" });
        }        
    }

    function onEdit(id, date, nodeCode, shipVia, capacityBucket, priorityRank, priorityFillPercentage, secondaryRank, secondaryFillPercentage) {
        let editData = {
            "fillId": id,
            "date": date,
            "nodeCode": nodeCode,
            "shipVia": shipVia,
            "capacityBucket": capacityBucket,
            "priorityRank": priorityRank,
            "priorityFillsPercentage": priorityFillPercentage,
            "secondaryRank": secondaryRank,
            "secondaryFillsPercentage": secondaryFillPercentage
        }
        setEditDetails({...editData});
        setPreviousDetails({...editData});
        setEditModal(true);
        setOutboxFunction(true);
    }

    function onInfo(statuses, history) {
        // setEditCapacityDetails({"date": date, "column": column, "capacity": capacityVal, "id":id, "statuses":statuses, "history":history});
        setInfoModal({ showInfoModal: true, statuses: statuses, history: history });
    }

    function blockE(event) {
        if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57) {
            event.preventDefault();
        }
    }

    async function uploadFile(file) {
        if (file && file.name) {
            let fileType = file.name.split('.')[1];
            if (file.name && (fileType == 'xlsx' || fileType == 'xls')) {
                await validateFile(file);
                setModalSpinner(true);
            } else {
                setShowModalSnack({ message: "Please select excel file !", status: "error" })
            }
        } else {
            setShowModalSnack({ message: "Please select excel file !", status: "error" })
        }
    }
    
    async function updatefills() {        
        const validNumber = new RegExp('^[0-9]');
        let numberValidation = (editDetails && validNumber.test(editDetails.priorityFillsPercentage) && validNumber.test(editDetails.priorityRank) && validNumber.test(editDetails.secondaryFillsPercentage) && validNumber.test(editDetails.secondaryRank));
        if(numberValidation) {
            if(previousDetails && previousDetails.priorityFillsPercentage != editDetails.priorityFillsPercentage || previousDetails.priorityRank != editDetails.priorityRank || previousDetails.secondaryFillsPercentage != editDetails.secondaryFillsPercentage || previousDetails.secondaryRank != editDetails.secondaryRank){
                if(editDetails.priorityFillsPercentage <= editDetails.secondaryFillsPercentage){
                    try {
                        const res = await apiService.put(
                            API_INTERFACE_URI.UPDATE_FILLS, editDetails
                        );
                        if (res && res.data && res.data.records) {
                            setEditModal(false);
                            getFillsData(currentStartDate);
                            setShowSnack({ message: "Capacity Fills updated successfully !", status: "success" });
                        }
                    }
                    catch (e) {
                        console.log('error', e);
                        setShowUpdtModalSnack({ message: e.data.errors[0].message, status: "error" });
                    }
                }else{
                    setShowUpdtModalSnack({ message: "Secondary Percentage should be greater than \r\n or equal to  Priority Percentage!", status: "error" });
                }
            } else {
                setEditModal(false);
            }
        } else {
            setShowUpdtModalSnack({ message: "Please Enter Valid Values.", status: "error" });
        }
    }

    const sortByDate = () =>{
        if(sortOrderDate == 'asc'){
            setConsolidatedResult(consolidatedResult.sort((a,b) => (new Date(a.date).getTime() < new Date(b.date).getTime()) ? -1 : ((new Date(b.date).getTime() < new Date(a.date).getTime()) ? 1 : 0)))
        }
        if(sortOrderDate == 'des'){
            setConsolidatedResult(consolidatedResult.reverse())
        }
        setSortOrderDate(sortOrderDate == 'asc' ? 'des' : 'asc');
    }

    function sortByPR() {
        if (sortOrderPR == 'asc') {
            setConsolidatedResult(consolidatedResult.sort((a, b) => (a.priorityRank < b.priorityRank) ? -1 : ((b.priorityRank < a.priorityRank) ? 1 : 0)))
        }
        if (sortOrderPR == 'des') {
            setConsolidatedResult(consolidatedResult.reverse())
        }
        setSortOrderPR(sortOrderPR == 'asc' ? 'des' : 'asc');
    }

    function sortBySR() {
        if (sortOrderSR == 'asc') {
            setConsolidatedResult(consolidatedResult.sort((a, b) => (a.secondaryRank < b.secondaryRank) ? -1 : ((b.secondaryRank < a.secondaryRank) ? 1 : 0)))
        }
        if (sortOrderSR == 'des') {
            setConsolidatedResult(consolidatedResult.reverse())
        }
        setSortOrderSR(sortOrderSR == 'asc' ? 'des' : 'asc');
    }

    function sortByPP() {

        if (sortOrderPP == 'asc') {

            setConsolidatedResult(consolidatedResult.sort((a, b) => (a.priorityFillPercentage < b.priorityFillPercentage) ? -1 : ((b.priorityFillPercentage < a.priorityFillPercentage) ? 1 : 0)))
        }
        if (sortOrderPP == 'des') {
            setConsolidatedResult(consolidatedResult.reverse())
        }
        setSortOrderPP(sortOrderPP == 'asc' ? 'des' : 'asc');
    }

    function sortBySP() {
        if (sortOrderSP == 'asc') {
            setConsolidatedResult(consolidatedResult.sort((a, b) => (a.secondaryFillPercentage < b.secondaryFillPercentage) ? -1 : ((b.secondaryFillPercentage < a.secondaryPercentage) ? 1 : 0)))
        }
        if (sortOrderSP == 'des') {
            setConsolidatedResult(consolidatedResult.reverse())
        }
        setSortOrderSP(sortOrderSP == 'asc' ? 'des' : 'asc');
    }

    function onDismissSnack(prop) {
        setShowSnack(false);
        setShowModalSnack(false);
        setShowUpdtModalSnack(false);
    }
    
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 main-container" style={{ minHeight: (height - 120) + 'px' }}>
            {snackMessage && snackMessage.message ?
                <SnackBar  onDismissFunction={() => onDismissSnack()}
                    params={{ "status": snackMessage.status, "message": snackMessage.message }}
                />
                : null}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-align-left">
                    <Text font={"title-6"} as={"h6"} className="fills-text"> Priority & Secondary Fills </Text>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 fillsBtn no-right-padding  no-left-padding">
                    <Button onClick={() => getFillsData(startDate)} variant="primary"><Icon name="Search" /></Button>
                    &nbsp;&nbsp;
                    {showResults &&
                        <Button onClick={() => downloadExcel()}>
                            <Icon name="Download" />
                        </Button>
                    }
                    &nbsp;&nbsp;

                    {(groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) &&
                        <Button onClick={() => setVisible(!visible)}>
                            <Icon name="Upload" />
                        </Button>
                    }
                    <div className="text-align-left upload-fills-modal">
                        <Modal
                            onDismiss={() => setVisible(!visible)}
                            isOpen={visible}
                            headerSlot={"Upload File"}
                            footerSlot={
                                <ButtonGroup>
                                    <div className="upload-fills-btn">
                                        <Button disabled={modalSpinner} onClick={() => uploadFile(selectedFile)} size="small">
                                            Upload
                                        </Button>
                                    </div>
                                    <div className="cancel-fills-btn">
                                        <Button
                                            onClick={() => cancelUpload()}
                                            size="small"
                                            variant="secondary"
                                            disabled={modalSpinner}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </ButtonGroup>
                            }
                        >
                             {snackModalMessage && snackModalMessage.message ? 
                                        <SnackBar   onDismissFunction={() => onDismissSnack()} 
                                                    params={{"status": snackModalMessage.status, "message": snackModalMessage.message}} 
                                        /> 
                                : null}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                                className="upload-fills-modal-content"
                            >
                                <TextField
                                    id="file"
                                    type="file"
                                    subtitle="Select File"
                                    ref={inputRef}
                                    accept=".xlsx, .xls"
                                    onChange={(e) => handleFileInput(e.target.files[0])}
                                />
                                {modalSpinner &&
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 fills-modal-spinner ">
                                        <Spinner size="large" />
                                    </div>
                                }
                            </div>
                        </Modal>
                    </div>
                </div>
                <form className="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 no-right-padding">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 text-align-left padding-right-point-3-rem fills-input-box">
                        <TextField
                            id="start-date"
                            onChange={e => onStartDate(e)}
                            value={startDate}
                            type="date"
                            subtitle="START DATE"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 text-align-left padding-right-point-3-rem fills-input-box">
                        <TextField
                            id="end-date"
                            onChange={e => setEndDate(e.target.value)}
                            value={endDate}
                            type="date"
                            subtitle="END DATE"
                            max={dateRange}
                            min={startDate}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 no-right-padding">
                        <div className="text-align-left fills-input-box">
                            <Select
                                name={"DCValue"}
                                isMulti
                                options={dcArray}
                                subtitle="DC"
                                hasErrors={false}
                                errorMessage="This is an error"
                                onChange={(newValue, object) => selectDCValues(newValue, object)}
                                value={selectedDC}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 no-right-padding">
                        <div className="text-align-left fills-input-box">
                            <Select
                                name={"CarrierValue"}
                                isMulti
                                options={carrierArray}
                                subtitle="CARRIER"
                                hasErrors={false}
                                errorMessage="This is an error"
                                onChange={(newValue, object) => selectCarrierValues(newValue, object)}
                                value={selectedCarrier}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 no-right-padding">
                        <div className="text-align-left fills-input-box ">
                            <Select
                                name={"CapacityBucketValue"}
                                isMulti
                                options={capacityBucketArray}
                                subtitle="CAPACITY BUCKET"
                                hasErrors={false}
                                errorMessage="This is an error"
                                onChange={(newValue, object) => selectCapacityBucketValues(newValue, object)}
                                value={selectedCapacityBucket}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 no-right-padding text-align-left fills-input-box">
                        <TextField
                            type="text"
                            subtitle="SEARCH"
                            id="search"
                            value={searchText}
                            onChange={onChangeSearch}
                            beforeSlot={<Icon name="Search" />}
                            placeholder="ShipVia"
                        />
                    </div>
                </form>
                {
                    showResults ?
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 fills-box no-right-padding" style={{ maxHeight: (height) + 'px' }}>
                                {
                                    !showSpinner && <div className="fills-table" >
                                        <Table id="fills-table">
                                            <thead>
                                                <tr>
                                                     <TableHeading className="fills-date-header-cell">Date <Icon name="Sort" size="s" onClick={() => sortByDate()} /></TableHeading>
                                                    <TableHeading className="fills-header-cell">DC</TableHeading>
                                                    <TableHeading className="fills-header-cell">ShipVia</TableHeading>
                                                    <TableHeading className="fills-header-cell">Capacity Bucket</TableHeading>
                                                    <TableHeading className="fills-header-cell">Priority Ranking <Icon name="Sort" size="s" onClick={() => sortByPR()} /></TableHeading>
                                                    <TableHeading className="fills-header-cell">Priority Percentage <Icon name="Sort" size="s" onClick={() => sortByPP()} /></TableHeading>
                                                    <TableHeading className="fills-header-cell">Secondary Ranking <Icon name="Sort" size="s" onClick={() => sortBySR()} /></TableHeading>
                                                    <TableHeading className="fills-header-cell">Secondary Percentage <Icon name="Sort" size="s" onClick={() => sortBySP()} /></TableHeading>
                                                    <TableHeading className="fills-header-cell">Info</TableHeading>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    consolidatedResult && consolidatedResult.map((row, index) => {
                                                        let failed = row.statuses.filter((e) => e.status == 'failure')
                                                        return (
                                                            <tr key={index}>
                                                                <TableCell className="fills-date-cell" hasError={failed.length > 0}>{row.date}</TableCell>
                                                                <TableCell className="fills-cell">{row.nodeName}</TableCell>
                                                                <TableCell className="fills-cell">{row.shipVia}</TableCell>
                                                                <TableCell className="fills-cell">{row.capacityBucket}</TableCell>
                                                                <TableCell className="fills-cell" onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row.fillsId, row.date, row.nodeCode, row.shipVia, row.capacityBucket, row.priorityRank, row.priorityFillPercentage, row.secondaryRank, row.secondaryFillPercentage) : {}}>{row.priorityRank}</TableCell>
                                                                <TableCell className="fills-cell" onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row.fillsId, row.date, row.nodeCode, row.shipVia, row.capacityBucket, row.priorityRank, row.priorityFillPercentage, row.secondaryRank, row.secondaryFillPercentage) : {}}>{row.priorityFillPercentage}</TableCell>
                                                                <TableCell className="fills-cell" onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row.fillsId, row.date, row.nodeCode, row.shipVia, row.capacityBucket, row.priorityRank, row.priorityFillPercentage, row.secondaryRank, row.secondaryFillPercentage) : {}}>{row.secondaryRank}</TableCell>
                                                                <TableCell className="fills-cell" onClick={() => (groups.includes('App.Transportation.OpsPortal.Test.Admin') || groups.includes('App.Transportation.OpsPortal.Prod.Admin')) ? onEdit(row.fillsId, row.date, row.nodeCode, row.shipVia, row.capacityBucket, row.priorityRank, row.priorityFillPercentage, row.secondaryRank, row.secondaryFillPercentage) : {}}>{row.secondaryFillPercentage}</TableCell>
                                                                <TableCell className="fills-action-cell" >
                                                                    <Icon name="Info" size="s" onClick={() => onInfo(row.statuses, row.history)} backgroundShape="circle" backgroundColor={"var(--eds-color-grey-2)"} />
                                                                </TableCell>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                                {showSpinner &&
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 fills-table-spinner">
                                        <Spinner size="large" />
                                    </div>
                                }
                                {infoModal.showInfoModal ? <InfoModal infoModal={infoModal} /> : null}
                                {showEditModal &&
                                    <div className="edit-modal text-align-left">
                                        <Modal
                                            onDismiss={() => setEditModal(!showEditModal)}
                                            isOpen={showEditModal}
                                            headerSlot={"Edit Details"}
                                            footerSlot={
                                                <ButtonGroup>
                                                    <div className="upload-btn">
                                                        <Button disabled={modalSpinner} onClick={() => updatefills()} size="small">
                                                            Update
                                                        </Button>
                                                    </div>
                                                    <div className="cancel-btn">
                                                        <Button
                                                            onClick={() => setEditModal(!showEditModal)}
                                                            size="small"
                                                            variant="secondary"
                                                            disabled={modalSpinner}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </ButtonGroup>
                                            }
                                            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                        >
                                            {snackUpdtModalMessage && snackUpdtModalMessage.message ? 
                                                    <SnackBar   onDismissFunction={() => onDismissSnack()} 
                                                                params={{"status": snackUpdtModalMessage.status, "message": snackUpdtModalMessage.message}} 
                                                    /> 
                                            : null}
                                            <div className="row modal-labels" >
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-point-5-rem">DC: <b>{editDetails.nodeCode}</b> &nbsp;&nbsp; SHIPVIA: <b>{editDetails.shipVia}</b> &nbsp;&nbsp; CAPACITY BUCKET: <b>{editDetails.capacityBucket}</b></div>
                                                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                    <TextField
                                                        id="priorityRank"
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        subtitle="PRIORITY RANKING"
                                                        onChange={(e) => setEditDetails({ ...editDetails, "priorityRank": e.target.value })}
                                                        onKeyPress={(event) => blockE(event)}
                                                        value={editDetails.priorityRank}
                                                    />
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                    <TextField
                                                        id="priorityFillPercentage"
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        subtitle="PRIORITY PERCENTAGE"
                                                        onChange={(e) => setEditDetails({ ...editDetails, "priorityFillsPercentage": e.target.value })}
                                                        onKeyPress={(event) => blockE(event)}
                                                        value={editDetails.priorityFillsPercentage}
                                                    />
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                    <TextField
                                                        id="secondaryRank"
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        subtitle="SECONDARY RANKING"
                                                        onChange={(e) => setEditDetails({ ...editDetails, "secondaryRank": e.target.value })}
                                                        onKeyPress={(event) => blockE(event)}
                                                        value={editDetails.secondaryRank}
                                                    />
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                    <TextField
                                                        id="secondaryFillPercentage"
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        subtitle="SECONDARY PERCENTAGE"
                                                        onChange={(e) => setEditDetails({ ...editDetails, "secondaryFillsPercentage": e.target.value })}
                                                        onKeyPress={(event) => blockE(event)}
                                                        value={editDetails.secondaryFillsPercentage}
                                                    />
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                }
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 row padding-top-bottom-1rem">
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 fills-paginationBtn">
                                    <Button disabled={!previousButton} onClick={() => onPrevious()}>
                                        Previous
                                    </Button>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 page-no">
                                    {/* Page: 1/10 */}
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 fills-paginationBtn">
                                    <Button disabled={endOfRecords} onClick={() => onNext()}>
                                        &nbsp;&nbsp;Next&nbsp;&nbsp;
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 padding-top-bottom-1rem no-right-padding">
                            <Text font={"title-6"} as={"h6"} className="">{displayMessage}</Text>
                        </div>
                }
            </div>
        </div>
    )
}
export default Fills;