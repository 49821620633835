import merge from "deepmerge";

const base = {
    appFullName: "Admin Tool UI",
    appName: "Admin Tool UI",
    description: "Admin Tool UI to manage rules",
    isProd: false,
    oAuth: {
      clientId: "nike.logistics.tms-admin-tool",
      redirectUri: window.location.origin,
      userInfo:
        "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/userinfo",
      scopes: [
        "transportation:tms.admin_tool.rules::read: transportation:tms.admin_tool.rules::create: transportation:tms.admin_tool.rules::update: transportation:tms.admin_tool.rules::delete: transportation:tms.admin_tool.rules::execute: profile openid email",
      ],
    },
    // homePageVideoLink:  "https://www.youtube.com/embed/J8RBvB9YAps"
    homePageVideoLink:  "https://player.vimeo.com/video/849331548?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  },
  local = merge(base, {
    // apiUrl:Test API url
    apiUrl: "https://top-portal-api-dev.logistics-test.nikecloud.com",
  }),
  dev = merge(base, {
    apiUrl: "https://top-portal-api-dev.logistics-test.nikecloud.com",
  }),
  prod = merge(base, {
    // apiUrl:production API url
    apiUrl: "https://top-portal-api-prod.logistics-prod.nikecloud.com",
    isProd: true,
    oAuth: {
      clientId: "nike.logistics.tms-admin-tool",
      redirectUri: window.location.origin,
      userInfo:
        "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/userinfo",
      scopes: [
        "transportation:tms.admin_tool.rules::read: transportation:tms.admin_tool.rules::create: transportation:tms.admin_tool.rules::update: transportation:tms.admin_tool.rules::delete: transportation:tms.admin_tool.rules::execute: profile openid email",
      ],
    }
  }),
  test = merge(base, {
    // apiUrl:Test API url
    apiUrl: "https://top-portal-api-test.logistics-test.nikecloud.com",
  }),
  stage = merge(base, {
    // apiUrl:Stage API url
    apiUrl: "http://localhost:8080",
  });

let env = dev;
if (
  window.location.origin.includes("admin-tool-prod.logistics-prod.nikecloud.com") ||
  window.location.origin.includes("admin-tool-prod.nike.com")
) {
  env = prod;
} else if (
  window.location.origin.includes("admin-tool-dev.logistics-test.nikecloud.com") ||
  window.location.origin.includes("admin-tool-dev.nike.com")
) {
  env = dev;
} else if (
  window.location.origin.includes("localhost:3000")
) {
  env = local;
} else if (
  window.location.origin.includes("admin-tool-test.logistics-test.nikecloud.com") 
) {
  env = test;
} else if (
  window.location.origin.includes(
    "admin-tool-stage.logistics-stage.nikecloud.com"
  ) ||
  window.location.origin.includes("admin-tool-stage.nike.com")
) {
  env = stage;
}
const config = env;
export default config;
