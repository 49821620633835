import axios from "axios";
import http from "../utilities/http";
import API_INTERFACE_URI from "../constants/uri.constants";
import { globalHeaders } from "../utilities/http";

const baseService = {
  post,
  get,
  put,
  callApi
};


async function post(url, body) {
  const headers = globalHeaders;
    return await axios
    .post(url, body, {
      headers : headers
})
    .then(handleResponse)
    .catch(error);
}

async function get(url) {
  const headers = globalHeaders;
  return axios.get(url, {
    headers: headers,
  })
    .then(handleResponse)
    .catch(error);
}

async function callApi() {
  const apiString = API_INTERFACE_URI.TEST_API;
  const headers = globalHeaders;
  const res =  await post(apiString, headers, {});
    return res
  
}

async function put(url, body) {
  const headers = globalHeaders;
  return axios
    .put(url, body, {
      headers: headers,
    })
    .then(handleResponse)
    .catch(error);
}



function handleResponse(response) {
  if (!http.isValidHttpCode(response.status)) {
    return Promise.reject(response);
  }
  return response;
}

function error(error) {
  let errorResponse = "";
  if (axios.isCancel(error)) {
    errorResponse = error.message;
  } else {
    errorResponse = error.response
      ? error.response
      : { response: { status: 500 } };
  }
  return Promise.reject(errorResponse);
}

export default baseService;
